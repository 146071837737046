import { useEffect, useState } from 'react';
import { Badge, Card, Col, List, Row, Space } from 'antd';
import { Collapse } from 'antd';
import { Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';

import { getMainLayout } from '@layouts/MainLayout';

import ColapseIcon from '../../svg/Colapse.svg';
import ExpandIcon from '../../svg/Expand.svg';

interface DataType {
  key: string;
  projectname: any;
  role: string;
  location: string;
  dates: any;
  status: any;
  tags: string[];
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Project Name',
    width: '400px',
    dataIndex: 'projectname',
    key: 'projectname',
  },
  {
    title: 'Role',
    width: '300px',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Location',
    width: '300px',
    dataIndex: 'location',
    key: 'location',
  },
  {
    title: 'Date(s)',
    width: '300px',
    key: 'dates',
    dataIndex: 'dates',
  },
  {
    title: 'Status',
    width: '300px',
    key: 'status',
    dataIndex: 'status',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color;
          let textColor = '#FFFFFF';
          switch (tag) {
            case 'Invited':
              color = '#FFBB0E';
              textColor = '#000000';
              break;
            case 'Available':
              color = '#8551D9';
              break;
            case 'Avail Check':
              color = '#F89020';
              break;
            case 'Avail Confirmed':
              color = '#14984C';
              break;
            case 'Released':
              color = '#494949';
              break;
            case 'Expired':
              color = '#868686';
              break;
            case 'Booked':
              color = '#1733C4';
              break;
            case 'Unavailable':
            case 'Denied':
            case 'Canceled':
            case 'Canceled w/ Pay':
            case 'No Show':
              color = '#E14A4A';
              break;
            case 'Misc Message':
              color = '#ECECEC';
              textColor = '#494949';
              break;
            case '':
            default:
              color = '#1733C4';
              break;
          }
          return (
            <Tag
              color={color}
              key={tag}
              style={{ backgroundColor: color, color: textColor }}
            >
              {tag}
            </Tag>
          );
        })}
      </>
    ),
  },
];

const mobileStatusColor = (tag) => {
  switch (tag) {
    case 'Invited':
      return '#FFBB0E';
    case 'Available':
      return '#8551D9';
    case 'Avail Check':
      return '#F89020';
    case 'Avail Confirmed':
      return '#14984C';
    case 'Released':
      return '#494949';
    case 'Expired':
      return '#868686';
    case 'Booked':
      return '#1733C4';
    case 'Unavailable':
    case 'Denied':
    case 'Canceled':
    case 'Canceled w/ Pay':
    case 'No Show':
      return '#E14A4A';
    case 'first_available':
      return '#1733C4';
    case 'Misc Message':
      return '#ECECEC';
    case '':
    default:
      return '#1733C4';
  }
};

const JobSectionList = (props): JSX.Element => {
  // const [isMobile, setIsMobile] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = window.innerWidth <= 768;

  let showBadge = false;
  if (props.sectionTitle === 'Action Required') {
    showBadge = true;
  }
  let showBookCount = false;
  if (
    props.sectionTitle === 'Bookings' ||
    props.sectionTitle === 'Invites' ||
    props.sectionTitle === 'Availability Checks'
  ) {
    showBookCount = true;
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const expandIcon = ({ isActive }) => {
    return isActive ? (
      <img src={ColapseIcon.src} alt='Colapse Icon' />
    ) : (
      <img src={ExpandIcon.src} alt='Expand Icon' />
    );
  };

  return (
    <>
      <Collapse
        style={{ paddingBottom: '32px' }}
        defaultActiveKey={props.defaultActiveKey}
        ghost
        expandIcon={expandIcon}
        onChange={toggleExpand}
      >
        <CustomCollapse
          key='1'
          header={
            <span style={{ fontWeight: 'bold' }}>
              {props.sectionTitle} {showBookCount && `(${props.badgeCount})`}{' '}
              {showBadge && <Badge color='#E14A4A' count={props.badgeCount} />}
            </span>
          }
        >
          {props.data.length > 0 ? (
            isMobile ? (
              <>
                <JobList>
                  {props.data.map((item) => (
                    <JobItem
                      key={item.key}
                      onClick={() => props.onClick(item)}
                      style={{ borderTop: '1px solid #D8D8D8' }}
                    >
                      <Row>
                        <Col span={18}>
                          <JobTitle>{item.projectname}</JobTitle>
                          <JobRole>{item.role}</JobRole>
                          <JobDates>{item.dates}</JobDates>
                        </Col>
                        <Col
                          span={6}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            height: '30px',
                          }}
                        >
                          <JobStatus
                            style={{
                              backgroundColor: mobileStatusColor(item.tags[0]),
                              padding: '10%',
                              width: 'fit-content',
                              borderRadius: '4px',
                              fontWeight: '400',
                              fontSize: '14px',
                              lineHeight: '19px',
                              alignItems: 'center',
                              display: 'flex',
                              whiteSpace: 'nowrap',
                              color:
                                item.tags[0] === 'Misc Message'
                                  ? '#494949'
                                  : item.tags[0] === 'Invited'
                                  ? '#000000'
                                  : 'white',
                            }}
                          >
                            {item.tags}
                          </JobStatus>
                        </Col>
                      </Row>
                    </JobItem>
                  ))}
                </JobList>
              </>
            ) : (
              // <Table style={{ width: '100%' }} pagination={false} columns={columns} dataSource={props.data} />
              <StyledTable
                style={{ width: '100%', cursor: 'pointer' }}
                pagination={false}
                columns={columns}
                dataSource={props.data.map((item) => ({
                  ...item,
                  onClick: () => {
                    if (props.onClick) {
                      props.onClick(item); // Invoke the onClick prop with the clicked item
                    }
                  },
                }))}
                onRow={(record) => ({
                  onClick: () => {
                    if (props.onClick) {
                      props.onClick(record); // Invoke the onClick prop with the clicked item
                    }
                  },
                })}
              />
            )
          ) : null}
        </CustomCollapse>
      </Collapse>
    </>
  );
};

JobSectionList.getLayout = getMainLayout;

export default JobSectionList;

const StyledTable = styled(Table)`
  && {
    width: 100%;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
  }
  .ant-table-tbody > tr > td {
    border-top: 1px solid #d8d8d8;
  }
`;
const CustomCollapse = styled(Collapse.Panel)`
  &&& .ant-collapse-content-box {
    padding: 0px !important;
  }
`;
const JobList = styled.div`
  display: grid;
`;

const JobItem = styled.div`
  background: #fff;
  padding: 20px;
  cursor: pointer;
`;

const JobTitle = styled.h3`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

const JobRole = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0;
  width: 300px;
`;

const JobDates = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0;
`;

const JobStatus = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  border-radius: 5px;
  text-align: center;
  color: #fdfdfd;
`;
