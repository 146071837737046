import type { InputProps } from 'antd';
import { Input } from 'antd';
import styled from 'styled-components';

import { SearchOutlined } from '@ant-design/icons';

const SearchBar = ({ placeholder, ...otherProps }: InputProps): JSX.Element => {
  return (
    <StyledInput
      placeholder={placeholder || 'Start typing...'}
      prefix={<SearchOutlined />}
      {...otherProps}
    />
  );
};

const StyledInput = styled(Input)`
  &.ant-input-affix-wrapper {
    padding: 8px 10px;
    font-size: ${(props) => props.theme.palette.textFontSize[7]};
    border: ${(props) => `1px solid ${props.theme.palette.border[2]}`};
    border-color: #d8d8d8;

    border-radius: 4px;

    &:hover {
      border-color: ${(props) => props.theme.palette.primary[0]};
    }

    &:active,
    &.ant-input-affix-wrapper-focused {
      border-color: ${(props) => props.theme.palette.primary[0]};
      box-shadow: ${(props) => props.theme.palette.shadow[1]};
    }

    input::placeholder {
      color: ${(props) => props.theme.palette.greyscale[5]};
    }

    svg {
      color: ${(props) => props.theme.palette.greyscale[5]};
      width: 1.3em;
      height: 1.3em;
    }
  }
`;

export default SearchBar;
