export const getFormattedDateRange = (dates: string[]): string => {
  return dates
    ?.map((date) => {
      const [_, month, day] = date.split('-');
      return `${parseInt(month)}/${parseInt(day)}`;
    })
    .join(', ');
};

export const getUnitToDatesMap = (
  bookingDatesObj: BookingDatesObj
): { [unit: string]: string[] } =>
  Object.keys(bookingDatesObj).reduce((acc, curr) => {
    bookingDatesObj[curr].forEach((bookingDate) => {
      if (curr in acc) {
        acc[curr].push(bookingDate.booking_date);
      } else {
        acc[curr] = [bookingDate.booking_date];
      }
    });
    return acc;
  }, {});

export const convertDateFormat = (inputDate: string): string => {
  const dateParts = inputDate.split('-');
  const convertedDate = `${dateParts[1]}/${dateParts[2]}/${dateParts[0].slice(
    2
  )}`;
  return convertedDate;
};

export const getFormattedDates = (dates: string[]): string[] =>
  dates
    ? dates
        .sort((a, b) => {
          const dateA = a.replace('/', '');
          const dateB = b.replace('/', '');
          return +dateA - +dateB;
        })
        .map((date, i) => {
          const [_, month, day] = date.split('-');
          const formattedDateString = `${month}/${day}`;
          return i === 0 ? formattedDateString : `, ${formattedDateString}`;
        })
    : [];

export const formatPrice = (price?: string): string => {
  if (!price) return '';
  const hasDollarSign = price.startsWith('$');
  const value = hasDollarSign ? price.slice(1) : price;
  const [numericValue, ...rest] = value.split('/');

  const formattedValue =
    Number(numericValue) % 1 ? Number(numericValue).toFixed(2) : numericValue;
  return `${hasDollarSign ? '$' : ''}${formattedValue}${
    rest.length ? '/' : ''
  }${rest}`;
};
