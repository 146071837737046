import { useState } from 'react';
import router from 'next/router';
import { Badge, Radio } from 'antd';
import styled from 'styled-components';

import { getMainLayout } from '@layouts/MainLayout';

const RadioButton = (props) => {
  const [status, setStatus] = useState(props.status);

  const getButtonStyle = (value) => {
    if (value === 'invite') {
      return {
        backgroundColor: '#F8BC20',
        color: 'black',
        height: '39px',
        lineHeight: '39px',
        fontSize: '12px',
        fontFamily: 'Inter',
        fontWeight: '600',
        letterSpacing: '0em',
      };
    } else if (value === 'avail') {
      return {
        backgroundColor: '#14984C',
        color: 'white',
        height: '39px',
        lineHeight: '39px',
        fontSize: '12px',
        fontFamily: 'Inter',
        fontWeight: '600',
        letterSpacing: '0em',
      };
    } else if (value === 'booked') {
      return {
        backgroundColor: '#1733C4',
        color: 'white',
        height: '39px',
        lineHeight: '39px',
        fontSize: '12px',
        fontFamily: 'Inter',
        fontWeight: '600',
        letterSpacing: '0em',
      };
    }
    return { backgroundColor: '#ECECEC', color: 'black' };
  };

  const handleButtonClick = (route) => {
    router.push(route);
    if (props.onButtonClick) {
      props.onButtonClick(route);
    }
  };

  const handleRadioChange = (e) => {
    setStatus(e.target.value);
    if (props.handleMenuChange) {
      props.handleMenuChange(e);
    }
  };

  return (
    <RadioButtonContainer>
      <Radio.Group
        defaultValue='invite'
        value={status}
        onChange={handleRadioChange}
      >
        <Radio.Button
          value='invite'
          style={{
            ...getButtonStyle(status === 'invite' ? 'invite' : ''),
            fontSize: '12px',
          }}
          onClick={() => handleButtonClick('Invites')}
        >
          Invites <BadgeWrapper count={props.badgeCountInvites} />
        </Radio.Button>
        <Radio.Button
          value='avail'
          style={{
            ...getButtonStyle(status === 'avail' ? 'avail' : ''),
            fontSize: '12px',
          }}
          onClick={() => handleButtonClick('AvailChecks')}
        >
          Avail Checks <BadgeWrapper count={props.badgeCountAvailChecks} />
        </Radio.Button>
        <Radio.Button
          value='booked'
          style={{
            ...getButtonStyle(status === 'booked' ? 'booked' : ''),
            fontSize: '12px',
          }}
          onClick={() => handleButtonClick('Bookings')}
        >
          Bookings <BadgeWrapper count={props.badgeCountBookings} />
        </Radio.Button>
      </Radio.Group>
    </RadioButtonContainer>
  );
};

RadioButton.getLayout = (page) => {
  return getMainLayout(page);
};

export default RadioButton;

const RadioButtonContainer = styled.div`
  .ant-radio-group {
    background-color: #ececec;
    border-start-start-radius: 50px;
    border-end-start-radius: 50px;
    border-start-end-radius: 50px;
    border-end-end-radius: 50px;
  }
  @media (max-width: 767px) {
    margin-left: 15px;
  }

  .ant-radio-button-wrapper {
    border: none;
  }

  .ant-radio-button-wrapper:first-child {
    border-start-start-radius: 50px;
    border-end-start-radius: 50px;
    border-start-end-radius: 50px;
    border-end-end-radius: 50px;
  }

  .ant-radio-button-wrapper {
    border-start-start-radius: 50px;
    border-end-start-radius: 50px;
    border-start-end-radius: 50px;
    border-end-end-radius: 50px;
  }

  .ant-radio-button-wrapper:last-child {
    border-start-end-radius: 50px;
    border-end-end-radius: 50px;
    border-start-start-radius: 50px;
    border-end-start-radius: 50px;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #ececec;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #ececec;
  }
`;

const BadgeWrapper = styled(Badge)`
  .ant-badge-count {
    background: #e14a4a;
    box-shadow: none;

    font-weight: 700;
    font-size: 11px;

    width: 100%;
  }
`;
